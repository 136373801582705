import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import JSONData from '../content/mycontent.json'

class Main extends React.Component {
    render() {
        let close = (
            <div
                className="close"
                onClick={() => {
                    this.props.onCloseArticle()
                }}
            ></div>
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="main"
                style={
                    this.props.timeout
                        ? { display: 'flex' }
                        : { display: 'none' }
                }
            >
                <article
                    id="work"
                    className={`${
                        this.props.article === 'work' ? 'active' : ''
                    } ${this.props.articleTimeout ? 'timeout' : ''}`}
                    style={{
                        display: 'none',
                        height: '90vh',
                        overflow: 'auto',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}
                >
                    <h2 className="major">Projects</h2>
                    <span className="image main">
                        <StaticImage
                            src="../images/reordify-ss.png"
                            alt="reordify logo"
                            placeholder="blurred"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                display: 'block',
                            }}
                        />
                    </span>

                    <div
                        style={{
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: '400',
                            fontSize: 'medium',
                            lineHeight: '1.5',
                        }}
                    >
                        {JSONData.WorkContent}
                    </div>
                    <br />
                    <div
                        style={{
                            fontFamily: 'Open Sans, sans-serif',
                            fontWeight: '400',
                            fontSize: 'medium',
                            lineHeight: '1.5',
                        }}
                    >
                        {JSONData.ProjectsStartLine}
                    </div>
                    <ul>
                        {JSONData.Projects.map((item) => {
                            return (
                                <li
                                    style={{
                                        fontFamily: 'Open Sans, sans-serif',
                                        fontWeight: '400',
                                        fontSize: 'medium',
                                        lineHeight: '1.5',
                                    }}
                                >
                                    <a target="_blank" href={item.link}>
                                        {item.name}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>

                    {/* not visible in gatsby build */}
                    {close}
                    {/* <div style={closeStyle} onClick={() => this.props.onCloseArticle()}>
            ← Back
          </div> */}
                </article>

                <article
                    id="contact"
                    className={`${
                        this.props.article === 'contact' ? 'active' : ''
                    } ${this.props.articleTimeout ? 'timeout' : ''}`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Contact</h2>
                    <p>Wehr Enterprises is a California corporation.</p>
                    <ul className="icons">
                        <li>
                            <a
                                target="_blank"
                                href={JSONData.github}
                                className="icon fa-github"
                            >
                                <span className="label">GitHub</span>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={JSONData.linkedin}
                                className="icon fa-linkedin"
                            >
                                <span className="label">Linkedin</span>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href={JSONData.emailid}
                                className="icon fa-envelope"
                            >
                                <span className="label">Email</span>
                            </a>
                        </li>
                    </ul>
                    {/* not visible in gatsby build */}
                    {close}
                    {/* <div style={closeStyle} onClick={() => this.props.onCloseArticle()}>
            ← Back
          </div> */}
                </article>
            </div>
        )
    }
}

Main.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}

export default Main
