import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export function Logo() {
    return (
        <StaticImage
            src="../images/went-logo.png"
            alt="logo"
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                display: 'block',
            }}
        />
    )
}
